import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', () => {
  const user = ref({
    id: '',
    name: 'Anonymous',
    email: ''
  })

  return { user }
})
